import "./questionnaires.css";

import React, { useEffect, useState } from "react";
import { withApollo } from "@apollo/client/react/hoc";

import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import env from "../common/env";
import { featureFlags } from "../common/feature-flags";
import { LoadingBar } from "../common/loading";
import {
  QuestionnairesContext,
  QuestionnairesProvider,
} from "../common/questionnaires/provider";
import { queryStringToObject } from "../dashboard/common/utils";
import { useLocation, useHistory } from "react-router-dom";

//
const GET_QJSON = gql`
  query ($mode: Mode, $questionnaireId: ID!, $jurisdictionId: ID) {
    questionnaire(
      id: $questionnaireId
      jurisdiction_id: $jurisdictionId
      mode: $mode
    ) {
      properties
      hooks
      questions
      version
    }
  }
`;

//
function View({ match }) {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const { user, logout } = useAuth0();
  let userFeatures = featureFlags.get();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = queryStringToObject(searchParams);
  const history = useHistory();
  const [context, setContext] = useState({});
  const prefix = `com.hostcompliance.questionnaire${
    match.params.jurisdictionId ? "." + match.params.jurisdictionId : ""
  }.${match.params.questionnaireId}`;
  const contextInstance = new QuestionnairesContext(prefix, ["session"]);

  const { client, data, loading } = useQuery(GET_QJSON, {
    variables: Object.assign(match.params, {
      mode: userFeatures.questionnnaires_test === true ? "TEST" : "LIVE",
    }),
  });

  useEffect(() => {
    if (!data) return;
    async function getToken() {
      if (!match.params.questionId) {
        match.params.questionId = data.questionnaire.properties.initial;
        history.replace(
          location.pathname + "/" + data.questionnaire.properties.initial,
          match.params
        );
      }

      const token = isAuthenticated ? await getAccessTokenSilently() : null;
      const decodedToken = token ? jwt_decode(token) : {};
      const context = {
        checksum: data.questionnaire.properties.checksum,
        config: {
          API_URL: env.API_URL,
          GOOGLE_MAPS_API_KEY: env.GOOGLE_MAPS_API_KEY,
        },
        params: { ...match.params, ...queryString },
        session: {
          token_type: "Bearer",
          access_token: token,
          access_token_decoded: {
            // TECHDEBT: remove, QJSON to move to scope
            payload: {
              scope: decodedToken.scope,
            },
          },
          metadata: decodedToken["https://api.hostcompliance.com/metadata"],
          scope: decodedToken.scope,
        },
        admin: decodedToken.iss === "https://hostcompliance.auth0.com/",
      };

      if (
        !isAuthenticated &&
        !["welcome", "signin"].includes(match.params.questionId) &&
        contextInstance.master.session
      ) {
        context.session = contextInstance.master.session;
        context.admin = false;
      }
      setContext(context);
    }
    if (!isLoading) {
      getToken();
    }
  }, [loading, data, isLoading]);

  const toggleFeature = (key) => {
    userFeatures[key] = !userFeatures[key];
    featureFlags.set(userFeatures);
    window.location.reload();
  };

  const signout = function () {
    client.resetStore();
    logout({ returnTo: window.location.origin });
  };

  const [sessionMenuEl, setSessionMenuEl] = useState(null);
  const sessionMenuOpen = Boolean(sessionMenuEl);

  const handleClick = (event) => {
    setSessionMenuEl(
      event.currentTarget.id === "session-button" ? event.currentTarget : null
    );
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="questionnaires">
      {isLoading && loading && <LoadingBar />}
      {!isLoading && !loading && context.config && (
        <div>
          {userFeatures.qjson_editor && (
            <AppBar
              position="static"
              color="default"
              style={{ boxShadow: "none", backgroundColor: "#c3c3c3" }}
            >
              <Toolbar>
                <IconButton onClick={() => toggleFeature("qjson_editor")}>
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>

                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1 }}
                >
                  Editor&apos;s Toolbar
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={userFeatures.questionnnaires_test}
                      onClick={() => toggleFeature("questionnnaires_test")}
                    />
                  }
                  label="Questionnaires mode:TEST"
                />

                <Button onClick={() => window.qj.context(true)}>Log</Button>

                <Button
                  onClick={() =>
                    window.qj.hook({
                      type: "Delete",
                      context: ["answers"],
                    })
                  }
                >
                  Clear
                </Button>
              </Toolbar>
            </AppBar>
          )}

          <header>
            {isAuthenticated && (
              <div style={{ position: "absolute", right: 0 }}>
                <Button
                  id="session-button"
                  aria-controls="session-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {user.name}
                </Button>
                <Menu
                  id="session-menu"
                  aria-labelledby="session-button"
                  anchorEl={sessionMenuEl}
                  open={sessionMenuOpen}
                  onClose={handleClick}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={signout}>Logout</MenuItem>
                </Menu>
              </div>
            )}

            <a
              className="logo"
              href={data.questionnaire.properties.logo_link_url}
            >
              <img
                src={data.questionnaire.properties.logo_url}
                alt="Jurisdiction's Logo"
              />
            </a>
          </header>
          <h1>{data.questionnaire.properties.locale.en.title}</h1>
          <QuestionnairesProvider
            qjson={data.questionnaire}
            questionId={match.params.questionId}
            context={context}
            prefix={`com.hostcompliance.questionnaire${
              match.params.jurisdictionId
                ? "." + match.params.jurisdictionId
                : ""
            }.${match.params.questionnaireId}`}
            goBack={goBack}
          ></QuestionnairesProvider>
        </div>
      )}
    </div>
  );
}

export default withApollo(View);
