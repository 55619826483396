const dot = require("dot-object");
import React, { useState, useEffect, useCallback } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Input,
  InputLabel,
} from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { deprecatedLog } from "./util";
import TextMaskCustom from "./input-text-mask";

const GET_COUNTRY_CODES = gql`
  {
    iso(id: "3166-1-alpha-2")
  }
`;

const GET_STATE_CODES = gql`
  query ($id: ID!) {
    iso(id: $id)
  }
`;

const COUNTRY_POSTAL_REGEX = {
  CA: "[A-Za-z]{1,2}\\d[A-Za-z -]?\\d?[A-Za-z]\\d{0,2}",
  US: "[0-9]{5}(?:-[0-9]{4})?",
};

function View({ question, context }) {
  const GOOGLE_MAPS_API_KEY = context.get().config.GOOGLE_MAPS_API_KEY;
  if (!GOOGLE_MAPS_API_KEY) {
    deprecatedLog("Context missing `config.GOOGLE_MAPS_API_KEY`");
  }

  //
  if (!question.input.style) {
    question.input.style = "basic";
  }

  const DEFAULT_VALUE = {
    basic: {
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      postal_code: "",
      country_code: question.input.country_code || "US",
    },
    autocomplete: {
      formatted: "",
    },
    semantic: {
      house_number: "",
      street_name: "",
      building_number: "",
      unit_number: "",
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      postal_code: "",
      country_code: question.input.country_code || "US",
    },
  };

  //
  const [postalCodePattern, setPostalCodePattern] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: Object.assign(
      DEFAULT_VALUE[question.input.style],
      context.pick("value")
    ),
  });

  // Map
  const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  //
  const { data: countryData, loading: countryLoading } =
    useQuery(GET_COUNTRY_CODES);

  if (question.input.style !== "autocomplete" && question.input.country_code) {
    deprecatedLog("input.country_code missing");
  }

  //
  const { data: stateData } = useQuery(GET_STATE_CODES, {
    skip: countryLoading,
    variables: {
      id: `3166-2-${formValues.output.country_code}`,
    },
  });

  const validate = (formValues) => {
    let tmp = { ...formErrors };

    // browser validation... // TODO
    // Object.keys(formValues).map(key=>tmp[key]=document.getElementById(key).validationMessage);

    // any additional validation...
    setPostalCodePattern(COUNTRY_POSTAL_REGEX[formValues.country_code]);

    setFormErrors(tmp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validate({ [name]: value });

    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    context.assign({
      value: values.output,
    });
  };

  //
  useEffect(() => {
    setPostalCodePattern(COUNTRY_POSTAL_REGEX[formValues.country_code]);
  }, [formValues]);

  // center={{
  //   lat: formValues.position.latitude,
  //   lng: formValues.position.longitude,
  // }}

  const onLoad = useCallback(function callback(map) {
    console.warn(map);

    // const bounds = new window.google.maps.LatLngBounds();
    // .. add marker?
    // map.fitBounds(bounds);

    // //
    // let position;
    // if (question.input.position) {
    //   console.log(1, question.input.position)
    //   position = context.pick(question.input.position)
    // } else {
    //   const tmpC = context.get().data || context.get();
    //   console.log(2, tmpC)
    //   position = tmpC.jurisdiction ? tmpC.jurisdiction.position : null;
    //   deprecatedLog('input.country_code missing');
    // }
    // console.log(55, position);
  }, []);

  return (
    <div>
      {question.input.map && 1 === 3 && isGoogleMapLoaded && (
        // eslint-disable-next-line react/no-unknown-property
        <div xs={12} md="auto">
          <GoogleMap
            mapContainerStyle={{
              width: "550px",
              height: "400px",
            }}
            zoom={16}
            onLoad={onLoad}
          >
            <Marker
              position={{
                lat: formValues.position.latitude,
                lng: formValues.position.longitude,
              }}
              onClick={() => {
                console.log(123);
              }}
            />
          </GoogleMap>
        </div>
      )}

      {question.input.style === "autocomplete" && (
        <FormControl error fullWidth>
          <TextField
            label={question.properties.locale.address}
            id="formatted"
            name="output.formatted"
            variant="filled"
            value={formValues.output.formatted}
            onChange={handleInputChange}
            required={question.input.required}
            readOnly={question.input.readonly}
            inputProps={{
              inputref: "ref-formatted",
            }}
            autoComplete="off"
            autofill="off"
            aria-describedby="formatted-helper-text"
          />
          <FormHelperText
            id="formatted-helper-text"
            style={{ fontSize: "0.9rem" }}
          >
            {formErrors.formatted}
          </FormHelperText>
        </FormControl>
      )}

      {question.input.style === "semantic" && (
        <Stack direction={{ xs: "column", sm: "row" }}>
          <FormControl error fullWidth>
            <TextField
              id="house_number"
              name="output.house_number"
              label={question.properties.locale.house_number}
              variant="filled"
              value={formValues.output.house_number}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-house_number",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="house_number-helper-text"
            />
            <FormHelperText
              id="house_number-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.house_number}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <TextField
              id="street_name"
              name="output.street_name"
              label={question.properties.locale.street_name}
              placeholder={question.properties.locale.placeholder}
              variant="filled"
              value={formValues.output.street_name}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-street_name",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="street_name-helper-text"
            />
            <FormHelperText
              id="street_name-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.street_name}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <InputLabel htmlFor="building_number">
              {question.properties.locale.building_number}
            </InputLabel>
            <Input
              id="building_number"
              name="output.building_number"
              variant="filled"
              value={formValues.output.building_number}
              inputComponent={TextMaskCustom}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-building_number",
                mask: `${question.properties.locale.building_number} **********`,
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="building_number-helper-text"
            />
            <FormHelperText
              id="building_number-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.building_number}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <InputLabel htmlFor="unit_number">
              {question.properties.locale.unit_number}
            </InputLabel>
            <Input
              id="unit_number"
              name="output.unit_number"
              variant="filled"
              value={formValues.output.unit_number}
              inputComponent={TextMaskCustom}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-unit_number",
                mask: `${question.properties.locale.unit_number} **********`,
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="unit_number-helper-text"
            />
            <FormHelperText
              id="unit_number-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.unit_number}
            </FormHelperText>
          </FormControl>
        </Stack>
      )}

      {question.input.style === "basic" && (
        <Stack direction={{ xs: "column", sm: "row" }}>
          <FormControl error fullWidth>
            <TextField
              id="line_1"
              name="output.line_1"
              label={question.properties.locale.line_1}
              variant="filled"
              value={formValues.output.line_1}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-line_1",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="line_1-helper-text"
            />
            <FormHelperText
              id="line_1-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.line_1}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <TextField
              id="line_2"
              name="output.line_2"
              label={question.properties.locale.line_2}
              variant="filled"
              value={formValues.output.line_2}
              onChange={handleInputChange}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-line_2",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="line_2-helper-text"
            />
            <FormHelperText
              id="line_2-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.line_2}
            </FormHelperText>
          </FormControl>
        </Stack>
      )}

      {question.input.style !== "autocomplete" && (
        <Stack direction={{ xs: "column", sm: "row" }}>
          <FormControl error fullWidth>
            <TextField
              id="city"
              name="output.city"
              label={question.properties.locale.city}
              variant="filled"
              value={formValues.output.city}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-city",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="city-helper-text"
            />
            <FormHelperText
              id="city-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.city}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="state">
              {question.properties.locale.state}
            </InputLabel>
            {!stateData ||
              (Object.keys(stateData.iso).length === 0 && (
                <TextField
                  id="state"
                  name="output.state"
                  variant="filled"
                  value={formValues.output.state}
                  onChange={handleInputChange}
                  required={question.input.required}
                  readOnly={question.input.readonly}
                  inputProps={{
                    inputref: "ref-state",
                  }}
                  autoComplete="off"
                  autofill="off"
                  aria-describedby="state-helper-text"
                />
              ))}

            {stateData && Object.keys(stateData.iso).length > 0 && (
              <Select
                labelId="state"
                id="state"
                name="output.state"
                inputProps={{
                  inputref: "ref-state",
                }}
                label={question.properties.locale.state}
                value={formValues.output.state}
                onChange={handleInputChange}
                required={question.input.required}
                readOnly={question.input.readonly}
              >
                {stateData &&
                  stateData.iso &&
                  Object.keys(stateData.iso).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {stateData.iso[key]}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            <FormHelperText
              id="state-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.state}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <TextField
              id="postal_code"
              name="output.postal_code"
              label={question.properties.locale.postal_code}
              variant="filled"
              value={formValues.output.postal_code}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-postal_code",
                pattern: postalCodePattern,
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="postal_code-helper-text"
            />
            <FormHelperText
              id="postal_code-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.postal_code}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="country_code-label">
              {question.properties.locale.country_code}
            </InputLabel>
            <Select
              labelId="country_code-label"
              id="country_code"
              name="output.country_code"
              inputProps={{
                inputref: "ref-country_code",
              }}
              label={question.properties.locale.country_code}
              value={formValues.output.country_code}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
            >
              {countryData &&
                countryData.iso &&
                Object.keys(countryData.iso).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {countryData.iso[key]}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>
      )}
    </div>
  );
}

export default View;
