const dot = require("dot-object");
import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";
import TextMaskCustom from "./input-text-mask";
import hooks from "./hooks";

function View({ question, context }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output: context.pick("value") || "",
  });

  useEffect(() => {
    setTimeout(() => {
      if (formValues.output !== "") {
        validate();
      }
    }, 1500);
    if (question.input.required) {
      context.assign({
        isSubmit: false,
      });
    }
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    setFormErrors({});
    context.assign({
      value: value,
    });
    if (value != "") {
      validate();
    }
  };

  const validate = async () => {
    let res = await hooks.gql(question.input.validator, context);
    if (res.errors) {
      context.assign({
        isSubmit: false,
      });
      setFormErrors(res.errors[0]);
    } else {
      context.assign({
        isSubmit: true,
      });
      setFormErrors({});
    }
  };

  return (
    <FormControl error fullWidth>
      <InputLabel htmlFor="text">{question.properties.locale.name}</InputLabel>
      <Input
        id="text"
        name="output"
        placeholder={question.properties.locale.placeholder}
        variant="filled"
        value={formValues.output}
        inputComponent={TextMaskCustom}
        onChange={handleInputChange}
        required={question.input.required}
        autoFocus
        inputProps={{
          maxLength: question.input.maxlength ? question.input.maxlength : 80,
          inputref: "ref-text",
          mask: question.input.mask || "***************",
        }}
        autoComplete="off"
        aria-describedby="text-helper-text"
      />
      <FormHelperText id="text-helper-text" style={{ fontSize: "0.9rem" }}>
        {formErrors.message}
      </FormHelperText>
    </FormControl>
  );
}

export default View;
