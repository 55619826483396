import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
} from "@mui/material";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

function View({ context, question }) {
  const invoice = context.pick("answers.invoice");
  const paymentMethods = invoice.payment_methods;
  const [selectedMethod, setSelectedMethod] = useState(
    paymentMethods?.[0].type
  );
  const countryCode = question.input.payment.country_code;
  const [achFormValues, setAchFormValues] = useState({
    accountHolderName: "",
    accountHolderType: "individual",
    routingNumber: "",
    routingNumber2: "",
    accountNumber: "",
    accountNumber2: "",
    countryCode: countryCode,
  });
  const [touchedAchFields, setTouchedAchFields] = useState({
    accountHolderName: false,
    routingNumber: false,
    routingNumber2: false,
    accountNumber: false,
    accountNumber2: false,
  });
  const [amount, setAmount] = useState("");
  const [cashFormValues, setCashFormValues] = useState({
    amount: "",
    receiptNumber: "",
  });
  const [checkFormValues, setCheckFormValues] = useState({
    amount: "",
    checkNumber: "",
  });
  const [sipaFormValues, setSipaFormValues] = useState({
    amount: "",
    sipaConfirmationCode: "",
  });
  const [errors, setErrors] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardExpiryError, setCardExpiryError] = useState("");
  const [cardCvcError, setCardCvcError] = useState("");
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const locale = context.master.locale;
  const account_holders = [
    {
      id: "individual",
      value: "Individual",
    },
    {
      id: "company",
      value: "Company",
    },
  ];

  const handleSelectedMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAchFormValues({
      ...achFormValues,
      [name]: value,
    });
    validateAchForm();
  };

  const validateAchForm = () => {
    let errors = {};

    if (
      touchedAchFields.accountHolderName &&
      !achFormValues.accountHolderName
    ) {
      errors.accountHolderName = locale.validation_required;
    }

    if (touchedAchFields.routingNumber && !achFormValues.routingNumber) {
      errors.routingNumber = locale.validation_required;
    }

    if (
      touchedAchFields.routingNumber2 &&
      (!achFormValues.routingNumber2 ||
        achFormValues.routingNumber !== achFormValues.routingNumber2)
    ) {
      errors.routingNumber2 = locale.validation_repeat;
    }

    if (touchedAchFields.accountNumber && !achFormValues.accountNumber) {
      errors.accountNumber = locale.validation_required;
    }

    if (
      touchedAchFields.accountNumber2 &&
      (!achFormValues.accountNumber2 ||
        achFormValues.accountNumber !== achFormValues.accountNumber2)
    ) {
      errors.accountNumber2 = locale.validation_repeat;
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAchFormFieldBlur = (e) => {
    const { name } = e.target;
    setTouchedAchFields((prev) => ({
      ...prev,
      [name]: true,
    }));

    validateAchForm();
    if (
      achFormValues.accountHolderName &&
      achFormValues.accountHolderType &&
      achFormValues.accountNumber &&
      achFormValues.routingNumber &&
      achFormValues.countryCode
    ) {
      createAchToken();
    }
  };

  const createCardToken = async () => {
    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded.");
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { token: cardToken, error: cardError } = await stripe.createToken(
      cardNumberElement
    );

    if (cardError) {
      console.error(cardError.message);
      console.error("Failed to create Card token.");
    } else {
      const paymentToken = { payment: { method: "CARD", token: cardToken.id } };
      context.assign({
        value: paymentToken,
      });
    }
  };

  const createAchToken = async () => {
    if (!stripe) {
      console.error("Stripe.js has not loaded.");
      return;
    }

    const { token: achToken, error: achError } = await stripe.createToken(
      "bank_account",
      {
        country: achFormValues.countryCode,
        currency: "USD",
        routing_number: achFormValues.routingNumber,
        account_number: achFormValues.accountNumber,
        account_holder_name: achFormValues.accountHolderName,
        account_holder_type: achFormValues.accountHolderType,
      }
    );

    if (achError) {
      console.error(achError.message);
    } else {
      const paymentToken = { payment: { method: "ACH", token: achToken.id } };
      context.assign({
        value: paymentToken,
      });
    }
  };

  const createSipaPayment = () => {
    if (sipaFormValues.amount && sipaFormValues.sipaConfirmationCode) {
      const paymentToken = {
        payment: {
          method: "SIPA",
          amount: parseFloat(sipaFormValues.amount),
          document_number: sipaFormValues.sipaConfirmationCode,
        },
      };
      context.assign({
        value: paymentToken,
      });
    } else {
      console.error("Values are missing for SIPA payment");
    }
  };

  const createCashPayment = () => {
    if (cashFormValues.amount && cashFormValues.receiptNumber) {
      const paymentToken = {
        payment: {
          method: "CASH",
          amount: parseFloat(cashFormValues.amount),
          document_number: cashFormValues.receiptNumber,
        },
      };
      context.assign({
        value: paymentToken,
      });
    } else {
      console.error("Values are missing for cash payment");
    }
  };

  const createCheckPayment = () => {
    if (checkFormValues.amount && checkFormValues.checkNumber) {
      const paymentToken = {
        payment: {
          method: "CHECK",
          amount: parseFloat(checkFormValues.amount),
          document_number: checkFormValues.checkNumber,
        },
      };

      context.assign({
        value: paymentToken,
      });
    } else {
      console.error("Values are missing for check payment");
    }
  };

  useEffect(() => {
    if (cardNumberValid && cardExpiryValid && cardCvcValid) {
      createCardToken();
    }
  }, [cardNumberValid, cardExpiryValid, cardCvcValid]);

  const onCardNumberElementChange = (event) => {
    setCardNumberValid(event.complete && !event.error);
    setCardNumberError(event.error ? event.error.message : "");
  };

  const onCardExpiryElementChange = (event) => {
    setCardExpiryValid(event.complete && !event.error);
    setCardExpiryError(event.error ? event.error.message : "");
  };

  const onCardCvcElementChange = (event) => {
    setCardCvcValid(event.complete && !event.error);
    setCardCvcError(event.error ? event.error.message : "");
  };

  const handleAmountChange = (event) => {
    const amount = event.target.value;
    setAmount(amount);

    if (!amount) {
      setErrors((prev) => ({ ...prev, amount: locale.validation_required }));
    } else {
      setErrors((prev) => ({ ...prev, amount: "" }));
    }
    setCashFormValues((prev) => ({ ...prev, amount: amount }));
    setCheckFormValues((prev) => ({ ...prev, amount: amount }));
    setSipaFormValues((prev) => ({ ...prev, amount: amount }));
  };

  const handleDocumentNumberChange = (event) => {
    const documentNumber = event.target.value;

    switch (selectedMethod) {
      case "CASH":
        setCashFormValues((prev) => ({
          ...prev,
          receiptNumber: documentNumber,
        }));
        createCashPayment();
        break;

      case "CHECK":
        setCheckFormValues((prev) => ({
          ...prev,
          checkNumber: documentNumber,
        }));
        if (!documentNumber) {
          setErrors((prev) => ({
            ...prev,
            checkNumber: locale.validation_required,
          }));
        } else {
          setErrors((prev) => ({ ...prev, checkNumber: "" }));
        }
        createCheckPayment();
        break;

      case "SIPA":
        setSipaFormValues((prev) => ({
          ...prev,
          sipaConfirmationCode: documentNumber,
        }));
        if (!documentNumber) {
          setErrors((prev) => ({
            ...prev,
            sipaConfirmationCode: locale.validation_required,
          }));
        } else {
          setErrors((prev) => ({ ...prev, sipaConfirmationCode: "" }));
        }
        createSipaPayment();
        break;

      default:
        break;
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        aria-label="payment method"
        name="payment-method"
        style={{ marginBottom: 40 }}
      >
        <RadioGroup
          aria-label="payment method"
          name="paymentMethod"
          value={selectedMethod}
          onChange={handleSelectedMethodChange}
          style={{ display: "flex", flexDirection: "row", gap: "16px" }}
        >
          {paymentMethods?.map((method) => (
            <FormControlLabel
              key={method.type}
              value={method.type}
              control={<Radio />}
              label={
                <Box>
                  <Typography variant="body1">
                    {method.type === "ACH" && locale.method_ach}
                    {method.type === "CARD" && locale.method_card}
                    {method.type === "CASH" && locale.method_cash}
                    {method.type === "CHECK" && locale.method_check}
                    {method.type === "SIPA" && locale.method_sipa}
                  </Typography>
                  {method.amount > 0 && (
                    <Typography variant="body2">
                      <br />
                      {locale.processor_fee} {method.amount.toFixed(2)}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    <br />
                    {locale.amount_due}{" "}
                    <strong>{method.total.toFixed(2)}</strong>
                  </Typography>
                </Box>
              }
              style={{ margin: 0 }}
            />
          ))}
        </RadioGroup>
      </Box>

      {selectedMethod === "ACH" && (
        <Box>
          <Box className="ach" layout="column" style={{ marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={locale.account_holder_name}
                  name="accountHolderName"
                  value={achFormValues.accountHolderName}
                  onChange={handleInputChange}
                  error={!!errors.accountHolderName}
                  helperText={errors.accountHolderName}
                  required
                  autoComplete="off"
                  onBlur={handleAchFormFieldBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={!!errors.accountHolderType}>
                  <InputLabel>{locale.account_holder_type}</InputLabel>
                  <Select
                    label={locale.account_holder_type}
                    name="accountHolderType"
                    value={achFormValues.accountHolderType}
                    onChange={handleInputChange}
                  >
                    {account_holders?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.accountHolderType && (
                    <FormHelperText>{errors.accountHolderType}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={locale.routing_number}
                  name="routingNumber"
                  value={achFormValues.routingNumber}
                  onChange={handleInputChange}
                  error={!!errors.routingNumber}
                  helperText={errors.routingNumber}
                  required
                  autoComplete="off"
                  onBlur={handleAchFormFieldBlur}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={locale.routing_number2}
                  name="routingNumber2"
                  value={achFormValues.routingNumber2}
                  onChange={handleInputChange}
                  error={!!errors.routingNumber2}
                  helperText={errors.routingNumber2}
                  required
                  autoComplete="off"
                  onBlur={handleAchFormFieldBlur}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={locale.account_number}
                  name="accountNumber"
                  value={achFormValues.accountNumber}
                  onChange={handleInputChange}
                  error={!!errors.accountNumber}
                  helperText={errors.accountNumber}
                  required
                  autoComplete="off"
                  onBlur={handleAchFormFieldBlur}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={locale.account_number2}
                  name="accountNumber2"
                  value={achFormValues.accountNumber2}
                  onChange={handleInputChange}
                  error={!!errors.accountNumber2}
                  helperText={errors.accountNumber2}
                  required
                  autoComplete="off"
                  onBlur={handleAchFormFieldBlur}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      {selectedMethod === "CARD" && (
        <Box
          display="flex"
          flexDirection="column"
          className="card"
          sx={{ mt: 2 }}
        >
          <Box sx={{ maxWidth: "60%" }}>
            <Box sx={{ mb: 2 }}>
              <Typography component="label" sx={{ display: "block", mb: 1 }}>
                Card Number
              </Typography>
              <CardNumberElement
                options={{
                  style: {
                    base: {
                      fontSize: "20px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                      backgroundColor: "#f9f9f9",
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  backgroundColor: "#f9f9f9",
                }}
                onChange={onCardNumberElementChange}
              />
              {cardNumberError && (
                <Typography color="error" variant="body2">
                  {cardNumberError}
                </Typography>
              )}
            </Box>

            <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
              <Box sx={{ flex: 1, mr: 1 }}>
                <Typography component="label" sx={{ display: "block", mb: 1 }}>
                  Expiration Date
                </Typography>
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: "20px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                        backgroundColor: "#f9f9f9",
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    backgroundColor: "#f9f9f9",
                  }}
                  onChange={onCardExpiryElementChange}
                />
                {cardExpiryError && (
                  <Typography color="error" variant="body2">
                    {cardExpiryError}
                  </Typography>
                )}
              </Box>

              <Box sx={{ flex: 1, ml: 1 }}>
                <Typography component="label" sx={{ display: "block", mb: 1 }}>
                  CVC
                </Typography>
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: "20px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                        backgroundColor: "#f9f9f9",
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    backgroundColor: "#f9f9f9",
                  }}
                  onChange={onCardCvcElementChange}
                />
                {cardCvcError && (
                  <Typography color="error" variant="body2">
                    {cardCvcError}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {["CASH", "CHECK", "SIPA"].indexOf(selectedMethod) > -1 && (
        <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {/* Amount Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={locale.amount}
                name="amount"
                value={amount || ""}
                onChange={handleAmountChange}
                error={!!errors.amount}
                helperText={errors.amount || ""}
                required
                autoComplete="off"
                inputProps={
                  {
                    // TODO `currency-mask` and `validator-range` functionality is pending.
                  }
                }
              />
            </Grid>

            {/* Receipt Number for CASH */}
            {selectedMethod === "CASH" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={locale.receipt_number}
                  name="receiptNumber"
                  value={cashFormValues.receiptNumber || ""}
                  onChange={handleDocumentNumberChange}
                  autoComplete="off"
                />
              </Grid>
            )}

            {/* Check Number for CHECK */}
            {selectedMethod === "CHECK" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={locale.check_number}
                  name="checkNumber"
                  value={checkFormValues.checkNumber || ""}
                  onChange={handleDocumentNumberChange}
                  error={!!errors.checkNumber}
                  helperText={errors.checkNumber || locale.validation_required}
                  required
                  autoComplete="off"
                />
              </Grid>
            )}

            {/* SIPA Confirmation Code for SIPA */}
            {selectedMethod === "SIPA" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={locale.sipa_confirmation_code}
                  name="sipaConfirmationCode"
                  value={sipaFormValues.sipaConfirmationCode || ""}
                  onChange={handleDocumentNumberChange}
                  error={!!errors.sipaConfirmationCode}
                  helperText={
                    errors.sipaConfirmationCode || locale.validation_required
                  }
                  required
                  autoComplete="off"
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default View;
